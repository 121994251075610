import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import { ModalSuscripcionActivaComponent } from './modal-suscripcion-activa/modal-suscripcion-activa.component';
import { VideoImpactoComponent } from './video-impacto/video-impacto.component';
import { ModalSmsComponent } from './modal-sms/modal-sms.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { ModalRegistroExitosoComponent } from './modal-registro-exitoso/modal-registro-exitoso.component';



@NgModule({
    declarations: [ModalSuscripcionActivaComponent, VideoImpactoComponent, ModalSmsComponent, ModalRegistroExitosoComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule
    ]
})
export class ModalModule { }
