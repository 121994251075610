import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-video-impacto',
  templateUrl: './video-impacto.component.html',
  styleUrls: ['./video-impacto.component.scss']
})
export class VideoImpactoComponent implements OnInit, AfterViewInit {
    @ViewChild('video')
    video: ElementRef;
    @ViewChild('modal')
    modal: ElementRef;
  constructor(
      public dialogRef: MatDialogRef<VideoImpactoComponent>
  ) { }

  ngOnInit(): void {
  }

    ngAfterViewInit(): void {
      const v = this.video.nativeElement as HTMLVideoElement;
      v.muted = true;
      v.play();
      this.modal.nativeElement.addEventListener('click', () => v.muted = false)
    }

}
