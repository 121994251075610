<div class="card-anuncio bg-card"
     [style.background-image]="'url(\''+STATIC_DIR + '/'+imageUrl+'\')'">
  <div class="row py-5 h-100 card-overlay align-items-center" >
    <div class="col-12 text-center" >
      <h1 class="card-anuncio=title px-1">{{title}}</h1>
      <h3 class="card-anuncio-desc py-3 px-1">
        {{description}}
      </h3>
    </div>
  </div>

</div>
