import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AnnounceComponent} from './announce/announce.component';
import {TutosComponent} from './tutos/tutos.component';
import {Paths} from './enums/paths';
import {RegistroPagoComponent} from './registro-pago/registro-pago.component';

const routes: Routes = [
  { path: Paths.INICIO, component: HomeComponent },
  { path: 'menu/' + Paths.INICIO, component: HomeComponent },
  { path: Paths.NOSOTROS, component: HomeComponent },
  { path: Paths.ANUNCIOS, component: AnnounceComponent},
  {path: Paths.TUTORIALES, component: TutosComponent},
  {path: Paths.REGISTRO, component: RegistroPagoComponent},
  {path: '', pathMatch: 'full', redirectTo: '/' + Paths.INICIO},
  {path: '**', pathMatch: 'full', redirectTo: '/' + Paths.INICIO }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
      onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
