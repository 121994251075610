<section class="py-5">
    <div class="py-3"></div>

    <div class="container">
    <div class="row py-3">
      <div class="col text-center">
        <h1 class="title-section" id="lan_tutoriales2">Tutoriales</h1>
      </div>
    </div>
    <div class="row ">
      <div class="col text-center">
        <img src="assets/titulos-morado.svg" class="w-100" height="9">
      </div>
    </div>
  </div>
    <div class="py-5"></div>
  <app-tutorial-card *ngFor="let it of items"
    [title]="it.titulo"
    [description]="it.texto"
    [videoUrl]="tuto_urls[0]"
  ></app-tutorial-card>

</section>
