import {Component, Input, OnInit} from '@angular/core';
import {SERVER_STATIC} from '../../../environments/environment';

@Component({
  selector: 'app-anuncio-card',
  templateUrl: './anuncio-card.component.html',
  styleUrls: ['./anuncio-card.component.scss']
})
export class AnuncioCardComponent implements OnInit {
  @Input()
  imageUrl: string;
  @Input()
  title: string;
  @Input()
  description: string;

  STATIC_DIR = SERVER_STATIC;

  constructor() { }

  ngOnInit(): void {
  }

}
