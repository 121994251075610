import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-suscripcion-activa',
  templateUrl: './modal-suscripcion-activa.component.html',
  styleUrls: ['./modal-suscripcion-activa.component.scss']
})
export class ModalSuscripcionActivaComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<ModalSuscripcionActivaComponent>
  ) { }

  ngOnInit(): void {
  }

}
