
<div class="dialog">
    <div class="row align-items-center titulo"
         mat-dialog-title>
        <div class="col text-center text-white h2 py-3">
            Bienvenido
        </div>
    </div>
    <div class="row align-items-center px-5"
         mat-dialog-title>
        <div class="col text-center font-weight-normal py-3">
            <div class="py-2"></div>
            Te has registrado con exito y tu cuenta de Guía Ü ha sido activada.
            <br>
            Revisa tu correo para comenzar a publicar anuncios en la App.
            <div class="py-4"></div>
            <div class="btn-regresar text-center " (click)="dialogRef.close()">
                ACEPTAR
            </div>
        </div>
    </div>
</div>
