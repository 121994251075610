<div class="dialog">
    <div class="row align-items-center titulo"
         mat-dialog-title>
        <div class="col text-center text-white h2 py-3">
           Código de verificación
        </div>
    </div>
    <div class="row align-items-center px-5"
         mat-dialog-title>
        <div class="col text-center font-weight-normal py-3">
            <div class="py-2"></div>
                Favor de introducir el código que hemos enviado a tu celular
            <div class="py-2"></div>
            <div class="py-2">
                <mat-form-field class="w-75">
                    <mat-label>Código de verificación</mat-label>
                    <input matInput type="text" required #codigo>
                </mat-form-field>
            </div>
            <div class="btn-regresar text-center " (click)="dialogRef.close(codigo.value)">
                ACEPTAR
            </div>
        </div>
    </div>
</div>
