<div class="row py-5" *ngIf="!textRight" >
    <div class="col" >
        <div class="card-container ">
            <div class="card-description">
                <div class=" h-100">
                    <div class=" h-100  ">
                        <div class="row px-5 py-5" style="padding-bottom: 0 !important;">
                            <div class="mx-5 my-4">
                                <h1 class=" card-text-title  text-wrap  ">
                                    {{title}}
                                </h1>
                                <img src="assets/card-div.svg" class="card-div card-div-right my-2" style="display: block; margin-left: auto">
                                <p class="text-wrap text-right">
                                    {{description}}
                                </p>
                            </div>
                        </div>
                        <div class="row " *ngIf="tiendas">
                            <div style="margin-left: 60px; margin-right: 10px "
                                 class="btn-deg text-center " (click)="goGPlay()">
                                GOOGLE PLAY
                            </div>
                            <div class="btn-deg text-center "
                                 style="margin-left: 10px"
                                 (click)="goAppstore()">
                                APP STORE
                            </div>
                        </div>
                        <div (click)="routingService.irAnuncios()" *ngIf="empezar" class="py-2">
                            <div class="btn-deg text-center w-75" (click)="goAppstore()">
                                EMPIEZA AQUÍ
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-image">
                <img [src]="imageUrl">
            </div>
        </div>
    </div>
</div>
<div class="row py-5" *ngIf="textRight">
    <div class="col" >
        <div class="card-container ">
            <div class="card-image">
                <img [src]="imageUrl">
            </div>
            <div class="card-description">
                <div class=" h-100">
                    <div class=" h-100  ">
                        <div class="row px-5 py-5" style="padding-bottom: 0 !important;">
                           <div class="mx-5 my-4">
                               <h1 class=" card-text-title text-left  text-wrap  ">
                                   {{title}}
                               </h1>
                               <img src="assets/card-div.svg" class="card-div text-left my-1" >
                               <p class="text-wrap text-left">
                                   {{description}}
                               </p>
                           </div>
                        </div>
                        <div class="row my-3" *ngIf="tiendas">
                            <div style="margin-left: 60px; margin-right: 10px "
                                 class="btn-deg text-center " (click)="goGPlay()">
                                GOOGLE PLAY
                            </div>
                            <div class="btn-deg text-center "
                                 style="margin-left: 10px"
                                 (click)="goAppstore()">
                                APP STORE
                            </div>
                        </div>
                        <div (click)="routingService.irAnuncios()" *ngIf="empezar" class="py-2">
                            <div class="btn-deg text-center w-75" (click)="goAppstore()">
                                EMPIEZA AQUÍ
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<br>
