import {Component, Input, OnInit} from '@angular/core';
import {RoutingService} from '../../routing.service';

@Component({
  selector: 'app-home-card',
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss']
})
export class HomeCardComponent implements OnInit {
    @Input()
    textRight: boolean;
    @Input()
    imageUrl: string;
    @Input()
    title: string;
    @Input()
    description: string;
    @Input()
    tiendas: boolean;
    @Input()
    empezar: boolean;
    @Input()
    id: string;
  constructor(public routingService: RoutingService) { }

  ngOnInit(): void {
  }

    goGPlay() {
        window.open('https://play.google.com/store/apps/details?id=com.bananageek.spiritu', "_blank");
    }

    goAppstore() {
        window.open('https://apps.apple.com/mx/app/spirit-%C3%BC/id1548402630', "_blank");
    }
}
