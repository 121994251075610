<nav class="navbar navbar-expand-md navbar-light my-bg shadow fixed-top"
    [ngClass]="route.url.includes(paths.INICIO) && !solid?
    'opacity' :
    'solid'
"
>
  <div class="" >
    <img (click)="router.irInicio()" src="assets/logo-header.png"
         style="margin-left: 61px; cursor:pointer;"
         width="152" height="21">
  </div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon white"></span>
  </button>
  <!-- add solid class to nav responsive -->
  <div class="collapse navbar-collapse" id="navbarResponsive">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item " style="margin: 0 10px 0 10px">
        <div class="nav-link text-white"
             [ngClass]="route.url.includes('inicio') ? 'underline' : ''"
             (click)="router.irInicio()"><span id="lan_inicio">Inicio</span> 
          <span class="sr-only">(current)</span>
        </div>
      </li>
      <li class="nav-item" style="margin: 0 10px 0 10px">
        <div class="nav-link text-white" id="lan_nosotros"
             [ngClass]="route.url.includes('nosotros') ? 'underline' : ''"
             (click)="router.irNosotros()" >Nosotros</div>
      </li>
      <li class="nav-item" style="margin: 0 10px 0 10px" >
        <div class="nav-link text-white" id="lan_anuncios"
             [ngClass]="route.url.includes('anuncios') ? 'underline' : ''"
             (click)="router.irAnuncios()">¿Cómo crear anuncios?</div>
      </li>
      <li class="nav-item" style="margin: 0 10px 0 10px" style="display: none;">
        <div class="nav-link text-white" id="lan_tutoriales"
             [ngClass]="route.url.includes('tutoriales') ? 'underline' : ''"
             (click)="router.irTutos()" >Tutoriales</div>
      </li>
      <li class="nav-item" style="margin: 0 10px 0 10px">
        <div id="selectLanguageDropdown" class="localizationTool"></div>
      </li>
    </ul>
  </div>
</nav>
<router-outlet ></router-outlet>
<div class="footer" *ngIf="!route.url.includes(paths.REGISTRO)">
  <div class="footer-content container">
    <div class="row text-center py-5 px-3">
      <div class="col footer-text-1" id="lan_tienesAlguna">
        ¿Tienes alguna duda o comentario? contacto@appspiritu.com
      </div>
    </div>
      <div class="py-2"></div>
    <div class="row text-center py-3">
      <div class="col ">
        <img src="assets/logo-footer.png" class="logo-footer"/>
      </div>
    </div>
    <div class="row text-center py-3">
      <div class="col ">
        <img src="assets/fb.svg" width="64" class="p-3" (click)="gofb()" style="cursor: pointer;"/>
        <img src="assets/insta.svg" class="p-3" (click)="goinsta()" style="cursor: pointer;"/>
      </div>
    </div>
    <div class="row text-center py-3">
      <div class="col ">
        <img src="assets/apple.svg" class="p-3" (click)="goAppstore()" style="cursor: pointer;"/>
        <img src="assets/gplay.svg" class="p-3"  (click)="goGPlay()" style="cursor: pointer;"/>
      </div>
    </div>
      <div class="row text-center py-4">
          <div class="col Spirit-2020-T">
              <span>Spirit Ü</span> ® 2022
          </div>
      </div>
  </div>
</div>
