<div class="carrousel"
     #carrusel
     [ngStyle]="{'background-image': 'url(' + backupImgs[index] + ')'}"
>
  <div class="row carrousel-content  align-items-center text-right">
    <div class="col-md-5 col-sm-1 offset-md-0 offset-md-6 flex-div car-cont"  >
      <div class="Forma-parte-de-la-co">
        {{(sliderItems[index] || {texto: ''}).texto }}
      </div>
      <div class="Line-6"></div>
      <div class="Regstrate-aqu-y-de">
        {{lowerText[index] }}
      </div>
      <div class="btns-tiendas py-3" *ngIf="index === 0">
        <img src="assets/apple.png" class="apple" (click)="goAppstore()">
        <img src="assets/google.png" class="play" (click)="goGPlay()">
      </div>
      <div class="btn-registrame py-3" *ngIf="index === 1" (click)="router.irAnuncios()">
        
      </div>
      <div class="btn-registrame py-3" *ngIf="index === 2" (click)="router.irRegistro()">
        
      </div>
    </div>
  </div>
  <div class="row text-center indicators" >
    <div class="col p-3 text-center">
      <div class="indicator" (click)="index = 0"
           [ngClass]="index === 0 ? 'selected' : ''"></div>
      <div class="indicator" (click)="index = 1"
           [ngClass]="index === 1 ? 'selected' : ''"></div>
      <div class="indicator" (click)="index = 2"
           [ngClass]="index === 2 ? 'selected' : ''"></div>
    </div>
  </div>
</div>

