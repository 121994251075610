import { Injectable } from '@angular/core';
import {RESTService} from './shared/modules/rest/services/rest.service';
import {Observable} from 'rxjs';
import {SERVER_STATIC} from '../environments/environment';
import {map} from 'rxjs/operators';
import {Profile} from './shared/classes/Profile';

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(private rest: RESTService) { }

  verificarEsGUIA(email: string): Observable<VerifyEmailRes> {
      return this.rest.post<VerifyEmailRes>('landing/verify-email', {
          username: email
      });
  }
    loadImage(localSrc: string): Observable<Blob> {
      return this.rest.loadImage(localSrc);
    }

    createAccount(param: { password: string; celular: string; email: string }): Observable<string> {
        return this.rest.post<string>('landing/new-account', param);
    }
    enviarCorreoGuia(email: string): Observable<boolean> {
      return this.rest.post<boolean>('landing/send-email-guia', {
          email
      });
    }

    getNameAndUid(email: string): Observable<Profile> {
        return this.rest.post('landing/get-info', {
            email
        });
    }
}
export enum VerifyEmailRes {
    ANUNCIANTE = 'anunciante',
    CONSUMIDOR = 'consumidor',
    NONE = 'none'
}
