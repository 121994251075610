import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Paths} from './enums/paths';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  $subject: BehaviorSubject<boolean>;
  observable$: Observable<boolean>;
  constructor(private router: Router) {
    this.$subject = new BehaviorSubject<boolean>(false);
    this.observable$ = this.$subject.asObservable();
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
    };
  }
  irInicio(): void {
      this.router.navigate(['/menu/' + Paths.INICIO]);
  }
  irNosotros(): void {
      this.router.navigate(['/' + Paths.NOSOTROS]);
  }
  irAnuncios(): void {
    this.router.navigate(['/' + Paths.ANUNCIOS]);
  }
  irTutos(): void {
    this.router.navigate(['/' + Paths.TUTORIALES]);
  }
  irRegistro(): void {
    this.router.navigate(['/' + Paths.REGISTRO]);
  }

}
