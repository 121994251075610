<section class="py-5">
    <div class="py-3"></div>
  <div class="container">
    <div class="row py-3">
      <div class="col text-center">
        <h1 class="title-section" id="lan_anuncios2">Anuncios</h1>
      </div>
    </div>

  </div>
  <div class="row py-3"></div>
  <!--<app-anuncio-card
    [imageUrl]="imagenes[0].imagen"
    title="Navega por el catálogo"
    description="Encuentra todo lo que buscas, conoce los servicios o productos y contacta a tu Guía Ü, así de sencillo formarás parte de la comunidad Spirit Ü"
  ></app-anuncio-card>
  <app-anuncio-card
      [imageUrl]="imagenes[1].imagen"
    title="Regístrate como Guía Ü"
    description="Tú puedes ser un Guía Ü, en solo 2 sencillos pasos: Regístrate gratis y comparte tu magia."
  ></app-anuncio-card>
  <app-anuncio-card
      [imageUrl]="imagenes[2].imagen"
    title="Publica tus productos o servicios"
    description="Como Guía Ü podrás dar de alta tu servicio o producto. Tendrás oportunidad
    de subir hasta 3 diferentes, teniendo así un alcance mucho más amplio de clientes."
  ></app-anuncio-card>-->
  <div class="card-steps">
    <div class="row py-5"></div>
    <div class="row ">
      <div class="col text-center ">
        <h1 class="title-section card-steps-title" id="lan_comoSer">Cómo ser un Guía Ü</h1>
      </div>
    </div>
    <div class="row ">
      <div class="col text-center">
        <img src="assets/titulo-blanco.svg" class="w-100" height="9">
      </div>
    </div>
    <div class="row py-3"></div>
    <div class="row py-3 h-100  align-items-start" >
      <div class="col-md-4 col-sm-1 px-5 text-center step-col" >
        <img src="assets/step1.png"/>
        <h2 id="lan_paso1">Paso 1</h2>
        <h1 class="azul" id="lan_Registrate1">Registrate</h1>
        <hr class="max-width">
        <p class="max-width" id="lan_soloTienesQue">
          Solo tienes que descargar la app y registrarte ingresando tus datos para ser un Guia Ü o un Spirit
        </p>
      </div>
      <div class="col-md-4 col-sm-1 px-5 text-center step-col" >
        <img src="assets/step2.png" />
        <h2 id="lan_paso2">Paso 2</h2>
        <h1 class="naranja" id="lan_Publica">Publica</h1>
        <hr class="max-width">
        <p class="max-width" id="lan_publicaTuservicio">
        Publica tu servicio o producto. Tendrás oportunidad de subir hasta 3 diferentes, teniendo así un alcance mucho más amplio de clientes.
        </p>
      </div>
      <div class="col-md-4 col-sm-1 px-5 text-center step-col" >
        <img src="assets/step3.png"/>
        <h2 id="lan_paso3">Paso 3</h2>
        <h1 class="verde" id="lan_Comparte">Comparte</h1>
        <hr class="max-width">
        <p class="max-width" id="lan_queremosQueTodos">
        Queremos que todos descubran la esencia y la magia de tus productos o servicios.
        </p>
      </div>
    </div>
    <div class="row py-5"></div>

  </div>
  <div class="row py-3"></div>
  <div class="container">
    <div class="row py-3">
      <div class="col text-center">
        <h1 class="title-section" id="lan_convierteteGuia">Conviértete en Guía Ü</h1>
      </div>
    </div>
    <div class="row ">
      <div class="col text-center">
        <img src="assets/titulos-morado.svg" class="w-100" height="9">
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 col-sm-1 offset-sm-0  offset-md-1 py-5">
        <video class="w-100" src="assets/video-sample.mp4" controls ></video>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-1 offset-sm-0  py-5">
        <img id="imageHolder" src="assets/celulares-anuncio.png" class="w-100 ">
      </div>
    </div>
    <div class="row">
      
    </div>
  </div>

</section>
