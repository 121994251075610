import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RoutingService} from '../routing.service';
import {MatDialog} from '@angular/material/dialog';
import {VideoImpactoComponent} from '../shared/modules/modal/video-impacto/video-impacto.component';
import {AngularFirestore} from '@angular/fire/firestore';
import {SERVER_STATIC} from '../../environments/environment';

declare function startAll():any;
declare function translate():any;
var firstTime = false;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
      @ViewChild('nosotros')
      nosotrosSec: ElementRef;
      @ViewChild('inicio')
      inicioSec: ElementRef;
      @ViewChild('page')
      pageSec: ElementRef;
      sliderItems: SliderItem[];
      nosotrosItems: NosotrosItem[];
      STATIC_DIR = SERVER_STATIC;
      lowerText = new Array();

      nosotrosImages = ['../assets/nosotros/1.png', '../assets/nosotros/2.png', '../assets/nosotros/3.png',
                        '../assets/nosotros/4.png', '../assets/nosotros/5.png']

      constructor(public routingService: RoutingService,
                  private dialog: MatDialog,
                  private firestore: AngularFirestore,
                  private router: Router) {
          this.sliderItems = [];
          this.nosotrosItems = [];
      }

      ngOnInit(): void {
        startAll();

        this.firestore.collection<SliderItem>('landing_slider').valueChanges()
            .subscribe((items) =>  {
              console.log(items)
                //this.sliderItems = items;
            });
        this.firestore.collection<NosotrosItem>('landing_nosotros').valueChanges()
            .subscribe((items) => {
                this.nosotrosItems = items;
            });

        this.lowerText = ['Descarga la App', 'Anuncia tu productos o servicios aquí', 'Descarga la App'];
        this.sliderItems = [
          {
            id:"",
            imagen: "carrusel/B4Ke4RicX0.png",
            texto: "Encuentra productos y servicios alternativos en Spirit Ü"
          },
          {
            id:"",
            imagen: "carrusel/Icqi6Z2sQK.png",
            texto: "Llega a más spirits con un solo click"
          },
          {
            id:"",
            imagen: "carrusel/6oUUCa4Nxb.png",
            texto: "Forma parte de la comunidad más grande en México Spirit Ü"
          }
        ];
        if(window.localStorage.getItem("SpiritULangLanding")== "en_GB")
        {
            this.lowerText = ['Download the App', 'Advertise your products or services here', 'Download the App'];
            this.sliderItems = 
            [
              {
                id:"",
                imagen: "carrusel/B4Ke4RicX0.png",
                texto: "Find alternative products and services at Spirit Ü"
              },
              {
                id:"",
                imagen: "carrusel/Icqi6Z2sQK.png",
                texto: "Reach more spirits with a single click"
              },
              {
                id:"",
                imagen: "carrusel/6oUUCa4Nxb.png",
                texto: "Be part of the largest community in Mexico Spirit Ü"
              }
            ];
            
        }
        //this.sliderItems.push(opbject);
      }

      ngAfterViewInit(): void {
        
        
        if (this.router.url.includes('nosotros')) {
          this.nosotrosSec.nativeElement
            .scrollIntoView();           //translate();
        } else if (this.router.url.includes('inicio')){
          
          const scrollToTop = window.setInterval(() => {
            const pos = window.pageYOffset;
            if (pos > 0) {
              window.scrollTo(0, pos - 65); // how far to scroll on each step
            } else {
              window.clearInterval(scrollToTop);
              if (!this.router.url.includes('menu')) {
                  this.dialog.open(VideoImpactoComponent);
              }
            }
            //translate();
          }, 16);
        }
        document.addEventListener('scroll', () => {
            if (window.scrollY >= (this.nosotrosSec.nativeElement as HTMLDivElement)
                .getBoundingClientRect().top ) {
               this.routingService.$subject.next(true);
            }
        });
        document.addEventListener('scroll', () => {
            if (window.scrollY < (this.nosotrosSec.nativeElement as HTMLDivElement)
                .getBoundingClientRect().top ) {
                this.routingService.$subject.next(false);
            }
        });
      }

    ngOnDestroy(): void {

    }

    goAppstore() {

    }

    goGPlay() {

    }
}
export class SliderItem {
    imagen: string;
    id: string;
    texto: string;
}
export class NosotrosItem {
    imagen: string;
    id: string;
    texto: string;
    titulo: string;
}
