export class Profile {
    id: string;
    sexo: string;
    celular: string;
    email: string;
    estado: string;
    fbcomplete: boolean;
    fecha_nacimiento: Date;
    fecha_registro: Date;
    image: string;
    name: string;
    pais: string;
    type: UserType;
    password: string;
}
export enum UserType {
    // TODO DEFINE USER TYPES
    NONE = '',
    CONSUMIDOR = 'consumidor',
    ANUNCIANTE = 'anunciante',
    ADMINISTRADOR = 'administrador',
    SUPER_ADMINISTRADOR = 'super_administrador'
}

