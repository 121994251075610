import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { AnnounceComponent } from './announce/announce.component';
import { TutosComponent } from './tutos/tutos.component';
import { HomeCardComponent } from './components/home-card/home-card.component';
import { CarruselComponent } from './components/carrusel/carrusel.component';
import {RoutingService} from './routing.service';
import { AnuncioCardComponent } from './components/anuncio-card/anuncio-card.component';
import { TutorialCardComponent } from './components/tutorial-card/tutorial-card.component';
import { RegistroPagoComponent } from './registro-pago/registro-pago.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import {RESTModule} from './shared/modules/rest/rest.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LandingService} from './landing.service';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {ModalModule} from './shared/modules/modal/modal.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import { BasicComponent } from './console/alerts/basic/basic.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    AnnounceComponent,
    TutosComponent,
    HomeCardComponent,
    CarruselComponent,
    AnuncioCardComponent,
    TutorialCardComponent,
    RegistroPagoComponent,
    BasicComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatInputModule,
        ModalModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatNativeDateModule,
        RESTModule,
        NgxIntlTelInputModule,
        AngularFireModule.initializeApp(environment.firebase),
        SweetAlert2Module.forRoot(),
    ],
  providers: [RoutingService, LandingService,
      { provide: MAT_DATE_LOCALE, useValue: 'es-MX' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
