import {Component, OnInit} from '@angular/core';
import {RoutingService} from './routing.service';
import {AnnounceComponent} from './announce/announce.component';
import {Router} from '@angular/router';
import {Paths} from './enums/paths';
declare function startAll():any;
declare function translate():any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent   {
  title = 'SpiritU';
  paths = Paths;
  solid = false;
  constructor(public router: RoutingService, public route: Router) {
      this.router.observable$.subscribe(value => this.solid = value);
  }

ngAfterViewInit(): void {
    console.log("main?");
    //startAll();
  }

goGPlay() {
    window.open('https://play.google.com/store/apps/details?id=com.bananageek.spiritu', "_blank");
}

goAppstore() {
    window.open('https://apps.apple.com/mx/app/spirit-%C3%BC/id1548402630', "_blank");
}

gofb() {
    window.open('https://www.facebook.com/appspiritu', "_blank");
}

goinsta() {
    window.open('https://www.instagram.com/spirit_u_', "_blank");
}

}
