<div id="recaptcha-container"></div>
<div class="registro-page" [formGroup]="registroForm">

    <div class="row  " style="max-height: calc(100vh - 76px);">
    <div class="col-md-8  img-back "
         [style.background-image]="'url(\''+STATIC_DIR+'/'+bgImg.imagen+'\')'"
    >

    <div class=" text-center registro-legend"  >
            <h1 class="px-1 titulo"><img src="assets/bienvenido.png" style="width: 100%"></h1>
            <h3 class=" py-3 px-1">
                <img src="assets/logo-footer.png" class="img-titulo"/>
            </h3>
        </div>
    </div>
    <div class="col-md-4 col-sm-1 py-3 no-padding">
      <div class="row  h-100  align-items-center" >
        <div class="col-12 text-center" >
          <div *ngIf="step1">
            <h1 class="form-ingresa-label my-5">
              Ingresa tu correo electrónico
            </h1>
            <div>
              <mat-form-field  class="w-75" style="max-width: 280px">
                <mat-label>Correo electrónico</mat-label>
                <input matInput type="text"  formControlName="email" maxlength="254" email>
              </mat-form-field>
            </div>
            <div class="py-5"></div>
            <div class="py-5">
                <div class="btn-deg text-center w-75"
                     style="max-width: 280px"
                     (click)="validarEmail()">
                    {{loading ? 'CARGANDO' : 'CONTINUAR'}}
                </div>
            </div>
          </div>
          <div *ngIf="step2" class="dos">
            <h1 class="form-ingresa-label py-3">
              Registro
            </h1>
            <h5>
              Ingresa tus datos
            </h5>
            <div class="py-3"></div>
            <div class="form-registro mx-5 py-3" >
              <mat-form-field  class="w-75">
                <mat-label>Nombre</mat-label>
                <input matInput  autocomplete="off" required
                       formControlName="name"
                       type="text" onkeypress="return soloLetras(event)">
              </mat-form-field>

                <div class="w-75 tel-row py-3">

                    <ngx-intl-tel-input

                        [preferredCountries]="[CountryISO.Mexico]"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        customPlaceholder="Celular"
                        [searchCountryFlag]="false"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.Mexico"
                        [maxLength]="15"
                        [tooltipField]="'Celular'"
                        [phoneValidation]="true"
                        [searchCountryPlaceholder]="'Buscar país'"
                        formControlName="celular"
                        onkeypress="return soloNumeros(event)"
                    ></ngx-intl-tel-input>
                </div>
                <mat-form-field  class="w-75">
                    <mat-label>Sexo</mat-label>
                    <mat-select formControlName="sexo" >
                        <mat-option value="Femenino" >Femenino</mat-option>
                        <mat-option value="Masculino" >Masculino</mat-option>
                        <mat-option value="Prefiero no decir">Prefiero no decir</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field (click)="picker.open()" class="w-75">
                    <mat-label>Fecha de nacimiento</mat-label>
                    <input matInput
                           formControlName="fecha_nacimiento"
                           [max]="maxDate" [matDatepicker]="picker" onkeypress="return soloFecha(event)">
                    <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker  #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field  class="w-75">
                    <mat-label>País</mat-label>
                    <mat-select formControlName="pais" >
                        <mat-option value="" disabled>Seleccione uno</mat-option>
                        <mat-optgroup label="Paises">
                            <mat-option *ngFor="let pais of paises.paises"
                                        [value]="pais.name" >{{pais.name}}</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-75" >
                    <mat-label>Estado</mat-label>
                    <mat-select formControlName="estado">
                        <mat-option value="" disabled>Seleccione uno</mat-option>
                        <mat-optgroup label="Estados" *ngIf="registroForm.get('pais').value === 'México'">
                            <mat-option *ngFor="let estadoMX of estados.estados"
                                        [value]="estadoMX.nombre" >{{estadoMX.nombre}}</mat-option>
                        </mat-optgroup>
                        <mat-optgroup label="Estados"  *ngIf="registroForm.get('pais').value !== 'México'">
                            <mat-option [value]="registroForm.get('pais').value">{{registroForm.get('pais').value}}</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
              <mat-form-field  class="w-75">
                <mat-label>Contraseña</mat-label>
                <input matInput  autocomplete="off" required
                       min="6"
                       formControlName="password"
                       type="password">
              </mat-form-field>
              <mat-form-field  class="w-75">
                <mat-label>Confirmar contraseña</mat-label>
                <input matInput autocomplete="off" required
                       #cpass
                       formControlName="repassword"
                       type="password">
              </mat-form-field>
            </div>
            <div class="py-3"></div>
            <div class="TyC px-5">
              Al registrarte a Spirit Ü aceptas nuestros
                <a href="https://appspiritu.com/static/terminosycondiciones.pdf" target="_blank"> Términos y Condiciones </a> y <a href="https://appspiritu.com/static/eliminacion.pdf" target="_blank">Aviso de Privacidad</a>
            </div>
            <div class="adjust" >
                <!-- <div id="btn-registro" (click)="registrar(cpass.value)" class="btn-deg text-center w-75" disabled>
                    {{loading ? 'CARGANDO' : 'CONTINUAR'}}
                </div> -->
                <button mat-flat-button id="btn-registro" (click)="registrar(cpass.value)" class="btn-deg text-center w-75" [class]="{dis: !registroForm.valid}" [disabled]="!registroForm.valid">
                    {{loading ? 'CARGANDO' : 'CONTINUAR'}}
                  </button>
            </div>
          </div>
          <div *ngIf="step3">
              <h1 class="form-ingresa-label h1 py-3">
                 ¡Hola {{nameStep3.split(' ')[0]}}!
              </h1>
            <div class="py-3"></div>
              <div class="desc">
                  Nos da gusto que formes parte de nuestra comunidad como Guía Ü.
              </div>
              <div class="py-5"></div>
                <div class="desc-bold">
                    Anúnciate sin costo
                </div>
              <div class="py-3">
                  <div class="btn-deg text-center w-75" (click)="pasaloAGuia()">
                      {{loading ? 'CARGANDO' : 'QUIERO ANUNCIARME'}}
                  </div>
              </div>
              <div class="py-5"></div>
              <div class="row">
                  <div class="links">
                      <a href="https://appspiritu.com/static/terminosycondiciones.pdf"> Términos y Condiciones </a>
                  </div>
                  <div class="links">
                      <a href="https://appspiritu.com/static/eliminacion.pdf">Aviso de Privacidad</a>
                  </div>
              </div>
          </div>
          <div *ngIf="step4">
            <h5>
              Seleccione un plan para continuar
            </h5>
            <div class="py-3"></div>
            <div class="form-registro mx-5 py-3">
              <div class="row">
                <div class="col-6 mensual">
                  Mensual
                </div>
                <div class="col-6">
                  MXN$ 99.00
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-6 trimestral">
                  Trimestral
                </div>
                <div class="col-6">
                  MXN$ 299.00
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-6 semestral">
                  Semestral
                </div>
                <div class="col-6">
                  MXN$ 499.00
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-6 anual">
                  Anual
                </div>
                <div class="col-6">
                  MXN$ 899.00
                </div>
              </div>
            </div>
            <div class="py-3"></div>
            <div class="TyC">
              Al registrarte a Spirit Ü aceptas nuestros
              Términos y Condiciones y Aviso de Privacidad
            </div>
            <div class="py-5">
              <img  src="assets/btn-pagar.png" class="w-50 btn-comenzar" />
            </div>
          </div>
          <div *ngIf="step5" class="dos"  [formGroup]="anunciarForm">
            <h1 class="form-ingresa-label h1 py-3">
                ¡Bienvenido!
             </h1>
           <div class="py-3"></div>
             <div class="desc-bold">
                Ahora eres un Guia Ü
             </div>
             <div class="py-3"></div>
               <div class="desc">
                Crea tu contraseña para <br> ingresar a tu panel de <br> administración Guia Ü
               </div>
            <div class="py-3"></div>
            <div class="form-registro mx-5 py-3" >
                <div class="w-75 tel-row py-3">

              <mat-form-field  class="w-75">
                <mat-label>Contraseña</mat-label>
                <input matInput  autocomplete="off" required
                       min="6"
                       formControlName="passworda"
                       type="password">
              </mat-form-field>
              <mat-form-field  class="w-75">
                <mat-label>Confirmar contraseña</mat-label>
                <input matInput autocomplete="off" required
                       #cpass
                       formControlName="repassworda"
                       type="password">
              </mat-form-field>
            </div>
            </div>
            <div class="adjust" >
                <!-- <div id="btn-registro" (click)="registrar(cpass.value)" class="btn-deg text-center w-75" disabled>
                    {{loading ? 'CARGANDO' : 'CONTINUAR'}}
                </div> -->
                <button mat-flat-button id="btn-registro" (click)="anunciar(cpass.value)" class="btn-deg text-center w-75" [class]="{dis: !anunciarForm.valid}" [disabled]="!anunciarForm.valid">
                    {{loading ? 'CARGANDO' : 'CONTINUAR'}}
                  </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
