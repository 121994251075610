<div class="container py-5">
  <div class="row align-items-md-center align-items-lg-start px-3">
    <div class="col-md-4 col-sm-1 text-right card-tuto-text">
      <h3>
        {{title}}
      </h3>
      <img src="assets/card-div-orange.svg" class="card-div-right w-75"/>
      <p>
        {{description}}
      </p>
    </div>
    <div class="col-md-8 col-sm-1 h-100">
      <video [src]="videoUrl" controls class="w-100"></video>
    </div>
  </div>
</div>
