import {Component, OnInit} from '@angular/core';
import {LandingService, VerifyEmailRes} from '../landing.service';
import {MatDialog} from '@angular/material/dialog';
import {ModalSuscripcionActivaComponent} from '../shared/modules/modal/modal-suscripcion-activa/modal-suscripcion-activa.component';
import {UserType} from '../shared/classes/Profile';
import {Paises} from '../shared/classes/Paises';
import {EstadosMX} from '../shared/classes/EstadosMX';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';
import firebase from 'firebase';

import {ModalSmsComponent} from '../shared/modules/modal/modal-sms/modal-sms.component';
import {AngularFirestore} from '@angular/fire/firestore';
import {ModalRegistroExitosoComponent} from '../shared/modules/modal/modal-registro-exitoso/modal-registro-exitoso.component';
import {RoutingService} from '../routing.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {SERVER_STATIC} from '../../environments/environment';

@Component({
  selector: 'app-registro-pago',
  templateUrl: './registro-pago.component.html',
  styleUrls: ['./registro-pago.component.scss']
})
export class RegistroPagoComponent implements OnInit {
    step1: boolean;
    step2: boolean;
    step3: boolean;
    step4: boolean;
    step5: boolean;
    email: string;
    confirmarPass: string;
    maxDate: Date;
    paises: Paises;
    estados: EstadosMX;
    registroForm: FormGroup;
    anunciarForm: FormGroup;
    SearchCountryField =  SearchCountryField;
    CountryISO = CountryISO;
    loading = false;
    private nameStep3: string;
    private uid: string;
    bgImg: {imagen: string};
    STATIC_DIR = SERVER_STATIC;
    constructor(
        private landingServ: LandingService,
        private dialog: MatDialog,
        private firestore: AngularFirestore,
        private fireAuth: AngularFireAuth,
        private router: RoutingService
    ) {
        this.email = '';
        this.confirmarPass = '';
        const today = new Date();
        this.maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
        this.paises = new Paises();
        this.estados = new EstadosMX();
        this.uid = null;
        this.bgImg = {imagen: ''};
        this.nameStep3 = '';
        this.registroForm = new FormGroup({
            sexo: new FormControl('', Validators.required),
            celular: new FormControl('', Validators.required),
            email: new FormControl(this.email, Validators.compose([
                Validators.required, this.emailValidator
            ])),
            estado: new FormControl(''),
            fbcomplete: new FormControl(true, Validators.required),
            complete: new FormControl(false, Validators.required),
            fecha_nacimiento: new FormControl(this.maxDate, Validators.required),
            name: new FormControl('', Validators.required),
            pais: new FormControl('', Validators.required),
            type: new FormControl(UserType.ANUNCIANTE, Validators.required),
            password: new FormControl('', Validators.compose(
                [Validators.min(6), Validators.required]
            )),
            repassword: new FormControl('', Validators.required)
        });
        this.anunciarForm = new FormGroup({
            passworda: new FormControl('', Validators.compose(
                [Validators.min(6), Validators.required]
            )),
            repassworda: new FormControl('', Validators.required)
        });
    }

    ngOnInit(): void {
        this.firestore.collection<{imagen: string}>('landing_suscripciones').doc('1').valueChanges()
            .subscribe((item) => {
                if (item) {
                    this.bgImg = item;
                }
            });
        this.irStep1();

    }

    emailValidator(control) {
        if (control.value) {
          const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
          return matches ? null : { 'invalidEmail': true };
        } else {
          return null;
        }
      }

    irStep1(): void {
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.step5 = false;
    }
    irStep2(): void {
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;
        this.step5 = false;
        // @ts-ignore
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'invisible'
        });
    }
    async irStep3(): Promise<void> {
        this.loading = true;
        try {
            const prof = await this.landingServ.getNameAndUid(this.email).toPromise();
            this.nameStep3 = prof.name;
            this.uid = prof.id;
        } catch (e) {
            console.error(e);
        }
        this.loading = false;
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
        this.step5 = false;
    }
    irStep4(): void {
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = true;
        this.step5 = false;
    }
    irStep5(): void {
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.step5 = true;
    }
    async registrar(cpass): Promise<void> {

        if (cpass === this.registroForm.get('password').value && this.registroForm.valid === true && this.loading === false) {
            // @ts-ignore
            const appVerifier = window.recaptchaVerifier;
            const newProfile = {... this.registroForm.getRawValue()} as any;
            newProfile.celular = newProfile.celular.e164Number;
            newProfile.fecha_registro = new Date();

            this.loading = true;
            const uid = await this.landingServ.createAccount({
                email: newProfile.email,
                password: newProfile.password,
                celular: newProfile.celular
            }).toPromise();
            if (uid !== null) {
                try {
                    this.uid = uid;
                    const confirmationResult = await firebase .auth().signInWithPhoneNumber(newProfile.celular, appVerifier);
                    const code = await this.dialog.open(ModalSmsComponent).afterClosed().toPromise();
                    await confirmationResult.confirm(code);
                    await this.firestore.collection('console_credentials').doc(uid).set({
                        email: newProfile.email,
                        enabled: true,
                        name: newProfile.name,
                        password: newProfile.password,
                        telefono: newProfile.celular,
                        type: UserType.ANUNCIANTE
                    });
                    delete newProfile.password;
                    await this.firestore.collection('profile').doc(uid).set(newProfile);

                    var today = new Date();
                    var fecha = new Date(newProfile.fecha_nacimiento);
                    //Restamos los años
                    var años = today.getFullYear() - fecha.getFullYear();
                    // Si no ha llegado su cumpleaños le restamos el año por cumplir
                    if (fecha.getMonth() > (today.getMonth()) || fecha.getDay() > today.getDay())
                        años--;

                    await this.firestore.collection('logs').add({
                        edad: años,
                        estado_republica: newProfile.estado,
                        evento: 'nuevo_usuario',
                        fecha: today,
                        sexo: newProfile.sexo,
                        tipo_usuario: 'guia'
                    });
                    await this.landingServ.enviarCorreoGuia(newProfile.email).toPromise();
                    await this.dialog.open(ModalRegistroExitosoComponent).afterClosed().toPromise();
                    this.router.irInicio();
                } catch (e) {
                    this.loading = false;
                    alert('Código invalido, vuelva a intentarlo.');
                    console.dir(e);
                    const logedIn = await this.fireAuth.signInWithEmailAndPassword(this.email, cpass);
                    await logedIn.user.delete();
                }
            } else {
                this.loading = false;
                alert('Hubo un error, favor de intentarlo más tarde');
            }
        }

    }
    async validarEmail(): Promise<void> {
        if (this.registroForm.get('email').valid) {
            this.email = this.registroForm.get('email').value;
            const result = await this.landingServ.verificarEsGUIA(this.registroForm.get('email').value).toPromise();
            if (result === VerifyEmailRes.ANUNCIANTE) {
                this.dialog.open(ModalSuscripcionActivaComponent);
            } else if (result === VerifyEmailRes.NONE){
                this.irStep2();
            } else if (result === VerifyEmailRes.CONSUMIDOR) {
                this.irStep3();
            }
        }
    }
    validaTelefono($event: KeyboardEvent): boolean {
        return /[0-9]/.test($event.key) && (this.registroForm.get('celular').value || '').length < 10;
    }

    validarCelular(): Promise<void>  {
        return new Promise<void>(resolve => resolve());
    }

    async pasaloAGuia(): Promise<void> {
        this.loading = true;
        try {

            this.firestore.collection<{imagen: string}>('landing_suscripciones').doc('1').valueChanges()
            .subscribe((item) => {
                if (item) {
                    this.bgImg = item;
                }
            });

            await this.firestore.collection('console_credentials').doc(this.uid).get().subscribe(async (doc) => {
                if(doc.exists){
                    await this.firestore.collection('profile').doc(this.uid).set({
                        type: UserType.ANUNCIANTE
                    }, {merge: true});
                    await this.firestore.collection('console_credentials').doc(this.uid).set({
                        type: UserType.ANUNCIANTE,
                        enabled: true
                    }, {merge: true});
                    await this.landingServ.enviarCorreoGuia(this.email).toPromise();
                    await this.dialog.open(ModalRegistroExitosoComponent).afterClosed().toPromise();
                    this.router.irInicio();
                }else{
                    this.irStep5();
                }
            });

        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async anunciar(cpass): Promise<void> {

        if (cpass === this.anunciarForm.get('passworda').value && this.anunciarForm.valid === true && this.loading === false) {
            this.loading = true;
            await this.firestore.collection('profile').doc(this.uid).set({
                type: UserType.ANUNCIANTE
            }, {merge: true});
            await this.firestore.collection('console_credentials').doc(this.uid).set({
                email: this.email,
                password: this.anunciarForm.get('passworda').value,
                enabled: true,
                type: UserType.ANUNCIANTE
            }, {merge: true});
            await this.landingServ.enviarCorreoGuia(this.email).toPromise();
            await this.dialog.open(ModalRegistroExitosoComponent).afterClosed().toPromise();
            this.router.irInicio();

        }else{
            alert('Las contraseñas no son iguales!');
        }

    }
}
