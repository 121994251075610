import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-sms',
  templateUrl: './modal-sms.component.html',
  styleUrls: ['./modal-sms.component.scss']
})
export class ModalSmsComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<ModalSmsComponent>
  ) { }

  ngOnInit(): void {
  }

}
