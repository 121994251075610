// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase : {
        apiKey: 'AIzaSyD7Gotb2dun6UK0_dl_3CCOh6SrjgI9QBY',
        authDomain: 'nomade-9420c.firebaseapp.com',
        databaseURL: 'https://nomade-9420c.firebaseio.com',
        projectId: 'nomade-9420c',
        storageBucket: 'nomade-9420c.appspot.com',
        messagingSenderId: '98186314420',
        appId: '1:98186314420:web:225a60e5f77d2f3791cde1',
        measurementId: 'G-JQTHNX5KE7'
    }
};
export const SERVER_API = 'https://appspiritu.com/api';
export const SERVER_STATIC = 'https://appspiritu.com/static';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
