import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {SERVER_STATIC} from '../../environments/environment';

declare function startAll():any;

@Component({
  selector: 'app-tutos',
  templateUrl: './tutos.component.html',
  styleUrls: ['./tutos.component.scss']
})
export class TutosComponent implements OnInit {
    items: TutorialItem[];
    STATIC_DIR = SERVER_STATIC;
    tuto_urls = ['../assets/tutoriales/video-sample.mp4']

  constructor(
      private firestore: AngularFirestore
  ) {
      this.items = [];
  }

  ngOnInit(): void {
    startAll();
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 65); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
      //startAll();
    }, 16);
    this.firestore.collection<TutorialItem>('landing_tutoriales').valueChanges()
        .subscribe((items) =>  {
          console.log(items)
            //this.sliderItems = items;
        });

    this.items = [
          {
              video: "tutoriales/jl6PWfhbu9.mp4",
              texto: "Lo único que necesitas es un sí.",
              titulo: "Spirit Ü"
          },
          {
              video: "tutoriales/kMejHpzb0R.mp4",
              titulo: "Sé un Guía Ü",
              texto: "Regístrate y comienza a ofrecer tus servicios y productos."
          },
          {
              video: "tutoriales/ulhET4KaGE.mp4",
              texto: "¿Y tú, eres un Guía Ü o un Spirit?",
              titulo: "¿QUÉ SON LOS GUÍAS Ü Y LOS SPIRITS?"
          },
          {
              video: "tutoriales/5biuyke56O.mp4",
              titulo: "¿CÓMO REGISTRARTE?",
              texto: "¿Qué esperas para registrarte? Es fácil y rápido."
          }
      ]

    if(window.localStorage.getItem("SpiritULangLanding")== "en_GB")
    {
      this.items = [
        {
            video: "tutoriales/jl6PWfhbu9.mp4",
            texto: "All you need is a yes.",
            titulo: "Spirit Ü"
        },
        {
            video: "tutoriales/kMejHpzb0R.mp4",
            titulo: "Be a Guide Ü",
            texto: "Register and start offering your services and products"
        },
        {
            video: "tutoriales/ulhET4KaGE.mp4",
            texto: "What about you? Are you a Guide Ü or a Spirit?",
            titulo: "What are Spirits and the Guides Ü?"
        },
        {
            video: "tutoriales/5biuyke56O.mp4",
            titulo: "How to register?",
            texto: "What are you waiting for? It’s fast and super simple!"
        }
    ]
    }
  }

}
export class TutorialItem {
    constructor(
        public titulo: string = 'Nuevo tutorial',
        public texto: string = 'Descripción del nuevo tutorial',
        public video: string = ''
    ) {
    }
}
