import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-registro-exitoso',
  templateUrl: './modal-registro-exitoso.component.html',
  styleUrls: ['./modal-registro-exitoso.component.scss']
})
export class ModalRegistroExitosoComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<ModalRegistroExitosoComponent>
  ) { }

  ngOnInit(): void {
  }

}
