import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {RoutingService} from '../routing.service';
import {AngularFirestore} from '@angular/fire/firestore';
declare function startAll():any;

@Component({
  selector: 'app-announce',
  templateUrl: './announce.component.html',
  styleUrls: ['./announce.component.scss']
})
export class AnnounceComponent implements OnInit {
    public imagenes: AnuncioItem[];

  constructor(
        public router: RoutingService,
        private firestore: AngularFirestore
  ) {
      this.imagenes = [{imagen: ''}, {imagen: ''}, {imagen: ''}];
  }

  ngOnInit(): void {
    startAll();
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 65); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
    this.firestore.collection<AnuncioItem>('landing_anuncios').valueChanges()
        .subscribe((items) => {
            this.imagenes = items;
        });
  }
}
export class AnuncioItem {
    imagen: string;
}
