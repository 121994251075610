<div #page>
    <!-- Full Page Image Header with Vertically Centered Content -->
    <header  >
        <div #inicio></div>
        <app-carrusel
            [sliderItems]="sliderItems"
            [lowerText]="lowerText"
        ></app-carrusel>
    </header>
    <div #nosotros  class="row py-3"></div>
    <!-- Page Content -->
    <section class="py-5">
        <div class="container">
            <div class="row">
                <video controls="true" src="assets/impacto.mp4" style="width: 80%; height: auto; margin-left:10%;"></video>
            </div>
        </div>
    </section>

    <section class="py-5">
        <div class="container">
            <div class="row">
                <div class="col text-center">
                    <h1 class="title-section" id="Nosotros2">Nosotros</h1>
                </div>
            </div>
            <div class="row ">
                <div class="col text-center">
                    <img src="assets/titulos-morado.svg" class="w-100" height="9">
                </div>
            </div>
            <div class="row py-5">
                <div class="col">
                    <div  class="card-container">
                        <div class="card-image"><img src="assets/nosotros/1.png"></div>
                        <div class="card-description">
                            <div  class="h-100">
                                <div  class="h-100">
                                    <div  class="row px-5 py-5" style="padding-bottom: 0 !important;">
                                        <div class="mx-5 my-4">
                                            <h1  class="card-text-title text-left text-wrap" id="lan_queEsSpirit"> ¿Qué es Spirit Ü? </h1>
                                            <img src="assets/card-div.svg" class="card-div text-left my-1">
                                            <p class="text-wrap text-left" id="lan_plataformaGratuita"> Spirit Ü es una plataforma gratuita donde personas te 
                                                conectarán con servicios y productos alternativos. Tú llamas lo que vibras y con Spirit Ü podrás encontrar gente que vibre en tu misma frecuencia. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div  class="row py-5">
                <div  class="col">
                    <div  class="card-container">
                        <div class="card-description">
                            <div _ngcontent-dau-c129="" class="h-100">
                                <div _ngcontent-dau-c129="" class="h-100">
                                    <div _ngcontent-dau-c129="" class="row px-5 py-5" style="padding-bottom: 0 !important;">
                                        <div _ngcontent-dau-c129="" class="mx-5 my-4">
                                            <h1 _ngcontent-dau-c129="" class="card-text-title text-wrap" id="lan_queSonLosGuias"> ¿Qué son los Guía Ü? </h1>
                                            <img _ngcontent-dau-c129="" src="assets/card-div.svg" class="card-div card-div-right my-2" style="display: block; margin-left: auto;">
                                            <p _ngcontent-dau-c129="" class="text-wrap text-right" id="lan_losGuiasUson"> Los Guías Ü son personas locales o viajeras que quieren ofrecer algún producto y/o servicio alternativo, sabemos que tus anuncios serán únicos y creemos que merecen ser más conocidos por personas. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div _ngcontent-dau-c129="" class="card-image"><img _ngcontent-dau-c129="" src="../assets/nosotros/2.png"></div>
                </div>
            </div></div>
            
            <div class="row py-5">
                <div class="col">
                    <div  class="card-container">
                        <div class="card-image"><img src="assets/nosotros/3.png"></div>
                        <div class="card-description">
                            <div  class="h-100">
                                <div  class="h-100">
                                    <div  class="row px-5 py-5" style="padding-bottom: 0 !important;">
                                        <div class="mx-5 my-4">
                                            <h1  class="card-text-title text-left text-wrap" id="lan_queSonSpirits"> ¿Qué son los Spirits? </h1>
                                            <img src="assets/card-div.svg" class="card-div text-left my-1">
                                            <p class="text-wrap text-left" id="lan_losSpiritsSon"> Son personas que buscan algo fuera de lo convencional y/
                                                o su estilo de vida es más alternativo. En la app Spirit Ü
                                                encontrarás todo lo que buscas, solo necesitas un sí para
                                                convertirte en un Spirit.. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div  class="row py-5">
                <div  class="col">
                    <div  class="card-container">
                        <div class="card-description">
                            <div _ngcontent-dau-c129="" class="h-100">
                                <div _ngcontent-dau-c129="" class="h-100">
                                    <div _ngcontent-dau-c129="" class="row px-5 py-5" style="padding-bottom: 0 !important;">
                                        <div _ngcontent-dau-c129="" class="mx-5 my-4">
                                            <h1 _ngcontent-dau-c129="" class="card-text-title text-wrap" id="lan_encuentraTodoLoQue"> Encuentra todo lo que buscas </h1>
                                            <img _ngcontent-dau-c129="" src="assets/card-div.svg" class="card-div card-div-right my-2" style="display: block; margin-left: auto;">
                                            <p _ngcontent-dau-c129="" class="text-wrap text-right" id="lan_seUnGuia"> Sé un Guía Ü o un Spirit, este es el momento para registrarte y promover tus productos o servicios o bien, de encontrarlos. Pronto encontrarás Spirit Ü en Google Play para Android y en App </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div _ngcontent-dau-c129="" class="card-image"><img _ngcontent-dau-c129="" src="../assets/nosotros/4.png"></div>
                </div>
            </div></div>

            <div class="row py-5">
                <div class="col">
                    <div  class="card-container">
                        <div class="card-image"><img src="assets/nosotros/5.png"></div>
                        <div class="card-description">
                            <div  class="h-100">
                                <div  class="h-100">
                                    <div  class="row px-5 py-5" style="padding-bottom: 0 !important;">
                                        <div class="mx-5 my-4">
                                            <h1  class="card-text-title text-left text-wrap" id="lan_ofreceTusServicios"> Ofrece tus sevicios y productos </h1>
                                            <img src="assets/card-div.svg" class="card-div text-left my-1">
                                            <p class="text-wrap text-left" id="lan_registrateCreaTuPerfil"> Descarga la app y regístrate, crea tu perfil y conviértete en
                                                un Guía Ü. ¡Deja que más personas te conozcan, expande
                                                tus posibilidades y lleva tu negocio al siguiente nivel! </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>

    <section class="py-5">
        <div class="container">
            <div class="row">
                <video controls="true" src="assets/SpiritU_3.mp4" style="width: 80%; height: auto; margin-left:10%;"></video>
            </div>
        </div>
    </section>

</div>
