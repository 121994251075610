import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {LandingService} from '../../landing.service';
import {RoutingService} from '../../routing.service';
import {SliderItem} from '../../home/home.component';
import {SERVER_STATIC} from '../../../environments/environment';

@Component({
  selector: 'app-carrusel',
  templateUrl: './carrusel.component.html',
  styleUrls: ['./carrusel.component.scss']
})
export class CarruselComponent implements OnInit {

    @Input()
    sliderItems: SliderItem[];
    @Input()
    lowerText: string[];

    @ViewChild('carrusel')
    carruselDiv: ElementRef;
    index: number;

    imageBas64: string[];
    private errors: number;
    public readonly backupImgs: string[];
    STATIC_FOLDER = SERVER_STATIC + '/';

    constructor(private landingServ: LandingService, private router: RoutingService) {
        this.sliderItems = [];
        this.index = 0;
        this.backupImgs = ['../assets/carrusel/dummy-1.png', '../assets/carrusel/dummy-2.png', '../assets/carrusel/dummy-3.png'];
        this.imageBas64 = ['', '', ''];
        this.errors = 0;
    }

    ngOnInit(): void {
        this.index = 0;
        /*this.sliderItems.forEach((src, i) => {
            this.loadImg(src.imagen, i);
        });*/
        setInterval(() => {
            this.index = (this.index + 1) % 3;

        }, 10000);
    }
    private testLoad(src, index) {

    }
    private loadImg(src, i): void {
        this.landingServ.loadImage(src).subscribe((img) => {
            this.imageBas64[i] = URL.createObjectURL(img);
        }, error => {
            this.errors += 1;
            console.error('No se encontraron las imagenes dinamicas, usando las dummy ...');
            if (this.errors <= 6) {
                this.loadImg(this.backupImgs[i], i);
            }
        });
    }

    goGPlay() {
        window.open('https://play.google.com/store/apps/details?id=com.bananageek.spiritu', "_blank");
    }

    goAppstore() {
        window.open('https://apps.apple.com/mx/app/spirit-%C3%BC/id1548402630', "_blank");
    }

}
