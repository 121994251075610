import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tutorial-card',
  templateUrl: './tutorial-card.component.html',
  styleUrls: ['./tutorial-card.component.scss']
})
export class TutorialCardComponent implements OnInit {
  @Input()
  videoUrl: string;
  @Input()
  title: string;
  @Input()
  description: string;
  constructor() { }

  ngOnInit(): void {
  }

}
