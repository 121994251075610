

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RESTService} from './services/rest.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    RESTService
  ]
})
export class RESTModule { }
